var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "not-found"
  }, [_c('div', {
    staticClass: "not-found-container"
  }, [_c('pageNotFound'), _c('div', {
    staticClass: "not-found-button"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Kembali")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }